import {Fetcher} from '@remix-run/react';
import {useEffect} from 'react';

export const useFetcherSuccessCb = (
  cb: () => void,
  fetcher: Fetcher,
  deps: any[] = [],
) => {
  useEffect(() => {
    if (fetcher.state === 'idle' && fetcher.data?.success) {
      cb();
    }
  }, [fetcher.state, fetcher.data, cb, ...deps]);
};
